import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import AffineTransformationQueryRepositoryInterface from '../../../domain/repository/AffineTransformationQueryRepositoryInterface'
import AffineTransformationsCountQuery from '../../query/AffineTransformationsCountQuery'

export default class AffineTransformationsCountHandler implements QueryHandlerInterface
{
    private repo: AffineTransformationQueryRepositoryInterface;

    constructor(repo: AffineTransformationQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: AffineTransformationsCountQuery): Promise<any>
    {
        return this.repo.getCount(query.getPayload());
    }
}